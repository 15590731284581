@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'color';
@import 'components/slider';

body {
  background-color: #E3EDF4;
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../assets/fonts/nunito-sans-v6-latin/nunito-sans-v6-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/nunito-sans-v6-latin/nunito-sans-v6-latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('../assets/fonts/nunito-sans-v6-latin/nunito-sans-v6-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/nunito-sans-v6-latin/nunito-sans-v6-latin-regular.svg#NunitoSans')
      format('svg'); /* Legacy iOS */
}

/* montserrat-normal - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../assets/fonts/montserrat/montserrat_regular.woff2')
    format('woff2'),
}

/* montserrat-medium - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('../assets/fonts/montserrat/montserrat_medium.woff2')
    format('woff2'),
}

/* montserrat-semi-bold - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('../assets/fonts/montserrat/montserrat_semi_bold.woff2')
    format('woff2'),
}

/* montserrat-bold - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local(''),
    url('../assets/fonts/montserrat/montserrat_bold.woff2')
    format('woff2'),
}

/* roboto-light - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('../assets/fonts/roboto/roboto_light.woff2')
    format('woff2'),
}

/* roboto-normal - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../assets/fonts/roboto/roboto-normal.woff2')
    format('woff2'),
}

/* roboto-medium - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('../assets/fonts/roboto/roboto-medium.woff2')
    format('woff2'),
}

@layer utilities {
  .lcpq-flex-1-0-auto {
    flex: 1 0 auto;
  }

  .lcpq-rotate-safari-bug-v12 {
    transform: rotate(270deg);
    position: fixed;
  }
}
