.lcpq-slider[type='range'] {
  height: 32px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
.lcpq-slider[type='range']:focus {
  outline: none;
}
.lcpq-slider[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #ffffff;
  border-radius: 0px;
  border: 0px solid #010101;
}
.lcpq-slider[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #ffffff;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: #3183c8;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -9px;
}
.lcpq-slider[type='range']:focus::-webkit-slider-runnable-track {
  background: #ffffff;
}
.lcpq-slider[type='range']::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #ffffff;
  border-radius: 0px;
  border: 0px solid #010101;
}
.lcpq-slider[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #ffffff;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: #3183c8;
  cursor: pointer;
}
.lcpq-slider[type='range']::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
.lcpq-slider[type='range']::-ms-fill-lower {
  background: #ffffff;
  border: 0px solid #010101;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}
.lcpq-slider[type='range']::-ms-fill-upper {
  background: #ffffff;
  border: 0px solid #010101;
  border-radius: 0px;
  box-shadow: 0px 0px 0px #000000;
}
.lcpq-slider[type='range']::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000031;
  border: 0px solid #ffffff;
  height: 26px;
  width: 26px;
  border-radius: 15px;
  background: #3183c8;
  cursor: pointer;
}
.lcpq-slider[type='range']:focus::-ms-fill-lower {
  background: #ffffff;
}
.lcpq-slider[type='range']:focus::-ms-fill-upper {
  background: #ffffff;
}
