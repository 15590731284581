.spinIconSmall {
  transform: scale(1);
}

.spinIconLarge {
  transform: scale(3);
}

.spinIcon {
  margin-left: auto;
  margin-right: auto;
  color: var(--lcpq-green-color);
}

.spinIcon,
.spinIcon::after,
.spinIcon::before {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
}

.spinIcon::after,
.spinIcon::before {
  content: '';
  position: absolute;
  border-radius: 100px;
}

.spinIcon::before {
  animation: spinIconAnimation 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
  border: 3px solid transparent;
  border-top-color: currentColor;
}

.spinIcon::after {
  border: 3px solid;
  opacity: 0.2;
}

@keyframes spinIconAnimation {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
